import React from "react";

const LoadingSpinner = () => {
  return (
    <div className="loading-spinner__container">
      <div className="loading-spinner">
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
        <i>
          <b></b>
        </i>
      </div>
    </div>
  );
};

export default LoadingSpinner;
